import React, { useState, useEffect } from "react";
import "./Controle.css";
import { Table } from "react-bootstrap";


import { useAppContext } from "../libs/contextLib";
import { Redirect, useHistory, useParams } from "react-router";

  export default function Controle() {
    const { token } = useAppContext();
    if(!token) {
      return <Redirect to="/connexion" />;
    }

    

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setItem] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  console.log(id);
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  function test(props){
    
    fetch(process.env.REACT_APP_API_ENDPOINT+`/getDiagnostic/${id}?withbuses=1`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token
        },
        // body: JSON.stringify(credentials)
        }
      )
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItem(result);
        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }
  useEffect(() => {
    test()
  }, [])

console.log(item);
  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div className="Controle">
        <div className="lander">
          <h1>Controle</h1>
          <a
                  className="btn btn-primary"
                  href="#!"
                  onClick={() => history.goBack()}>Retour</a>
          <Table striped bordered hover>
            <thead>
              <tr>
              <th>Synchro</th>
                <th>type</th>
                <th>organismePresNom</th>
                <th>pulverisateurNumNational</th>
                <th>proprietaireRaisonSociale</th>
                <th>controleCodePostal controleCommune</th>
                <th>Date</th>
                <th>controleIsComplet</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{item.dateSynchro}</td>
                <td>{item.typeDiagnostic}</td>
                <td>{item.organismePresNom}</td>
                <td>{item.pulverisateurNumNational}</td>
                <td>{item.proprietaireRaisonSociale}</td>
                <td>{item.controleCodePostal} {item.controleCommune}</td>
                <td>{item.controleDateFin}</td>
                <td>{item.controleIsComplet}</td>
                <td>{item.origineDiag}</td>
              </tr>
          </tbody>
        </Table>


        </div>
      </div>
    );
  }


}