import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Login.css";
import { useAppContext } from "../libs/contextLib";
import { Redirect, useHistory } from "react-router";

export default function Login() {
	const { token,userSetToken, userHasAuthenticated } = useAppContext();
  const [login, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  console.log(token.length);
  if(token.length){
    return <Redirect to="/" />;
  }
  function validateForm() {
    return login.length > 0 && password.length > 0;
  }

    const handleSubmit = async e => {
		e.preventDefault();
		try {
			const dataResult = await loginUser({
        login,
        password
			});
			if(dataResult.auth){
				userSetToken(dataResult.token);
				userHasAuthenticated(true);
			}else{
				alert('Authentification echouée');
			}
		} catch (e) {
			alert(e.message);
		}
	}
	async function loginUser(credentials) {
	return fetch(process.env.REACT_APP_API_ENDPOINT+'/loginUser', {
	method: 'POST',
	headers: {
		'Content-Type': 'application/json'
	},
	body: JSON.stringify(credentials)
	})
	.then(data => data.json())
	}


  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Login</Form.Label>
          <Form.Control
            autoFocus
            type="type"
            value={login}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <p><a href="/mot-de-passe-oublie">Mot de passe oublié ? </a></p>
        <Button block size="lg" type="submit" disabled={!validateForm()}>
          Login
        </Button>
      </Form>
    </div>
  );
}