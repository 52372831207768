import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Forgotten.css";
import { useAppContext } from "../libs/contextLib";
import { Redirect, useHistory } from "react-router";

export default function Forgotten() {
	const { token,userSetToken, userHasAuthenticated } = useAppContext();
  const [email, setEmail] = useState("");
  const history = useHistory();

  console.log(token.length);
  function validateForm() {
    return email.length > 0;
  }

    const handleSubmit = async e => {
		e.preventDefault();
		try {
			const dataResult = await forgottenUser({
        email,
			});
			alert('Demande faite, vérifiez vos emails.');
      history.push('/');
		} catch (e) {
			alert(e.message);
		}
	}
	async function forgottenUser(credentials) {
	return fetch(process.env.REACT_APP_API_ENDPOINT+'/mot-de-passe-oublie', {
	method: 'POST',
	headers: {
		'Content-Type': 'application/json'
	},
	body: JSON.stringify(credentials)
	})
	.then(data => data.json())
	}


  return (
    <div className="Forgotten">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Votre email</Form.Label>
          <Form.Control
            autoFocus
            type="type"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Button block size="lg" type="submit" disabled={!validateForm()}>
          Envoyer un lien de réinitialisation 
        </Button>
      </Form>
    </div>
  );
}