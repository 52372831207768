import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Reset.css";
import { useAppContext } from "../libs/contextLib";
import { Redirect, useHistory, useLocation } from "react-router";

export default function Reset() {
	const { token,userSetToken, userHasAuthenticated } = useAppContext();
  const search = useLocation().search;
  const resettoken = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('email');
  const [resultGet, setGetResult] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();

  if(!email.length || !resettoken.length){
    return <Redirect to="/" />;
  }

 

  const [password, setPassword] = useState("");
  const [password_confirm, setPasswordConfirm] = useState("");

  function validateForm() {
    return password_confirm.length > 0 && password.length > 0 && password==password_confirm;
  }

    const handleSubmit = async e => {
		e.preventDefault();
		try {
			const dataResult = await postResetPassword({
        password,
        password_confirm,
        email,
        resettoken
			});
			if(dataResult){
				if(dataResult.reset){
          alert('Mot de passe modifié');
          history.push('/');
        }else{
          alert('Mot de passe non modifié !!');
        }
			}else{
				alert(dataResult.message);
			}
		} catch (e) {
			alert(e.message);
		}
	}
	async function postResetPassword(credentials) {
    return fetch(process.env.REACT_APP_API_ENDPOINT+'/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
    .then(data => data.json())
	}
	function getResetToken(credentials) {
    fetch(process.env.REACT_APP_API_ENDPOINT+`/reset-password/${email}/${resettoken}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        setIsLoaded(true);
        setGetResult(result);
      },
      // Remarque : il faut gérer les erreurs ici plutôt que dans
      // un bloc catch() afin que nous n’avalions pas les exceptions
      // dues à de véritables bugs dans les composants.
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
	}
  useEffect(() => {
    getResetToken()
  }, [])
  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else if (!resultGet.showForm) {
    return <div>Not found...</div>;
  }else {
  return (
    <div className="Reset">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control
            autoFocus
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Confirmation</Form.Label>
          <Form.Control
            type="password"
            value={password_confirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </Form.Group>
        <Button block size="lg" type="submit" disabled={!validateForm()}>
          Changer le mot de passe
        </Button>
      </Form>
    </div>
  );
  }
}