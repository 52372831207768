import React, { useState, useEffect } from "react";
import "./Client.css";
import { Table } from "react-bootstrap";


import { useAppContext } from "../libs/contextLib";
import { Redirect, useHistory, useParams } from "react-router";

  export default function Client() {
    const { token } = useAppContext();
    if(!token) {
      return <Redirect to="/connexion" />;
    }

    

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [item, setItem] = useState([]);
  const { id } = useParams();
  const history = useHistory();
  console.log(id);
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  function test(props){
    
    fetch(process.env.REACT_APP_API_ENDPOINT+`/getClients/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token
        },
        // body: JSON.stringify(credentials)
        }
      )
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItem(result);
        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }
  useEffect(() => {
    test()
  }, [])

console.log(item);
  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div className="Client">
        <div className="lander">
          <h1>Client</h1>
          <a
                  className="btn btn-primary"
                  href="#!"
                  onClick={() => history.goBack()}>Retour</a>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>SIREN</th>
                <th>Nom</th>
                <th>Contact</th>
                <th>Adresse</th>
                <th>Code Postal</th>
                <th>Ville</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{item.id}</td>
                <td>{item.numeroSiren}</td>
                <td>{item.raisonSociale}</td>
                <td>{item.nomExploitant} {item.prenomExploitant}</td>
                <td>{item.adresse}</td>
                <td>{item.codePostal}</td>
                <td>{item.commune}</td>
              </tr>
          </tbody>
        </Table>


        </div>
      </div>
    );
  }


}