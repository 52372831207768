import React, { useState, useEffect } from "react";
import "./Controles.css";
import { Table } from "react-bootstrap";


import { useAppContext } from "../libs/contextLib";
import { Redirect } from "react-router";

  export default function Controles() {
    const { token } = useAppContext();
    if(!token) {
      return <Redirect to="/connexion" />;
    }
    return (
      <div className="Controles">
        <div className="lander">
          <h1>Accueil</h1>
          <p>Contenu à définir</p>
        </div>
      </div>
    );


}