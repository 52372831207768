import React, { useState, useEffect } from "react";
import "./Organismes.css";
import { Table } from "react-bootstrap";


import { useAppContext } from "../libs/contextLib";
import { generatePath, Redirect, useHistory } from "react-router";
import { Link } from "react-router-dom";

  export default function Organismes() {
    const { token } = useAppContext();
    if(!token) {
      return <Redirect to="/connexion" />;
    }
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [totalRecordsPage, setTotalRecordsPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  
  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  function test(props){
    console.log(props);
    const page_to_call = props && props.page ? props.page : currentPage
    fetch(process.env.REACT_APP_API_ENDPOINT+`/getStructures?page=${page_to_call}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token
        },
        // body: JSON.stringify(credentials)
        }
      )
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.users);
          setTotalCount(result.totalItems);
          setTotalRecordsPage(result.totalPages);
          setCurrentPage(result.currentPage);

          let startPage, endPage;
          if (result.totalPages <= 10) {
              // less than 10 total pages so show all
              startPage = 1
              endPage = result.totalPages
          } else {
              // more than 10 total pages so calculate start and end pages
              if (result.currentPage <= 6) {
                  startPage = 1
                  endPage = 10
              } else if (result.currentPage + 4 >= result.totalPages) {
                  startPage = result.totalPages - 9
                  endPage = result.totalPages
              } else {
                  startPage = result.currentPage - 5
                  endPage = result.currentPage + 4
              }
          }

          let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
          (i) => startPage + i
          )
          setPages(pages)
        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }
  useEffect(() => {
    test()
  }, [])

console.log(items);
  if (error) {
    return <div>Erreur : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div className="Organismes">
        <div className="lander">
          <h1>Organismes</h1>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Nom</th>
                <th>Contact</th>
                <th>Adresse</th>
                <th>Code Postal</th>
                <th>Ville</th>
                <th>Modification</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
            {items.map(item => (
              <tr>
                <td>{item.id}</td>
                <td>{item.nom}</td>
                <td>{item.nomContact} {item.prenomContact}</td>
                <td>{item.adresse}</td>
                <td>{item.codePostal}</td>
                <td>{item.commune}</td>
                <td>{item.dateModificationCrodip}</td>
                <td>
                <a
                  className="btn btn-primary"
                  href={generatePath("/organisme/:id", { id: item.id})}
                >Détails</a>
                
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div>
        
         <div>
      {totalCount > 0 && (
        <div className="table-footer d-flex justify-content-between align-items-center">
          <nav className="pages">
            <ul className="pagination">
              <li
                className={
                  currentPage === 1 ? "disabled page-item" : "page-item"
                }
              >
                <a
                  href="#!"
                  className="page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    test({page : currentPage - 1});
                  }}
                >
                  Previous
                </a>
              </li>
              {pages.map(item => {
                  return (
                    <li
                      key={item}
                      className={
                        currentPage === item
                          ? "custom-disabled active page-item"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        href="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          test({page : item});
                        }}
                      >
                        {item}
                      </a>
                    </li>
                  );
                })}
              <li
                className={
                  currentPage + 1 > totalRecordsPage
                    ? "disabled page-item"
                    : "page-item"
                }
              >
                <a
                  className="page-link"
                  href="#!"
                  onClick={(e) => {
                    e.preventDefault();
                    test({page : currentPage + 1});
                  }}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>

      </div>
        </div>
      </div>
    );
  }


}