import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Organismes from "./containers/Organismes";
import Clients from "./containers/Clients";
import Client from "./containers/Client";
import Controles from "./containers/Controles";
import Controle from "./containers/Controle";
import Home from "./containers/Home";
import Agents from "./containers/Agents";
import Organisme from "./containers/Organisme";
import Forgotten from "./containers/Forgotten";
import Reset from "./containers/Reset";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
	  <Route exact path="/mot-de-passe-oublie">
        <Forgotten />
      </Route>
	  <Route exact path="/user/reset-password">
        <Reset />
      </Route>
	  <Route exact path="/controles">
        <Controles />
      </Route>
	  <Route exact path="/mes-clients">
 		<Clients />
	  </Route>
	  <Route exact path="/mon-organisme">
 		<Organismes />
	  </Route>
	  <Route exact path="/mes-agents">
        <Agents />
      </Route>
	  <Route exact path="/connexion">
 		<Login />
	  </Route>
	  
	  <Route exact path="/client/:id" component={Client}/>
	  <Route exact path="/organisme/:id" component={Organisme}/>
	  <Route exact path="/controle/:id" component={Controle}/>
	  {/* Finally, catch all unmatched routes */}
	  <Route>
	    <NotFound />
	  </Route>
    </Switch>
  );
}