import React, { useState, useEffect  } from "react";

import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";


import { AppContext } from "./libs/contextLib";
import { useHistory } from 'react-router-dom';


function App() {

  function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  }

  const [token, userSetToken] = useState( getSessionStorageOrDefault('token', '') );
  const [isAuthenticated, userHasAuthenticated] = useState( getSessionStorageOrDefault('isAuthenticated', '') );
  const history = useHistory();
  function handleLogout() {
    userHasAuthenticated(false);
    userSetToken('');
    sessionStorage.clear();
    history.push("/connexion");
  }
  
    useEffect(() => {
      sessionStorage.setItem('token', JSON.stringify(token));
    }, [token]);
    useEffect(() => {
      sessionStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
    }, [isAuthenticated]);


  return (
    <div className="App py-3 ">
      <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
        <LinkContainer to="/">
          <Navbar.Brand className="font-weight-bold text-muted">
            <img src="/logo.jpg" alt="Logo"/>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav activeKey={window.location.pathname}>
           {isAuthenticated ? (
            <>
              <LinkContainer to="/">
                <Nav.Link>Accueil</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/controles">
                <Nav.Link>Controles</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/mon-organisme">
                <Nav.Link>Mon Organisme</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/mes-clients">
                <Nav.Link>Mes Clients</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/mes-agents">
                <Nav.Link>Mes Agents</Nav.Link>
              </LinkContainer>
              <Nav.Link onClick={handleLogout}>Déconnexion</Nav.Link>
            </>
           ) : (
            <>
              <LinkContainer to="/connexion">
                <Nav.Link>Connexion</Nav.Link>
              </LinkContainer>
              
            </>
          )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, token, userSetToken }}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}

export default App;